import { createReducer, createSelector, on } from "@ngrx/store";

import { ArtistCertificationActions } from "../actions";
import { ArtistCertification } from "src/app/models/user/artist-certification";

export interface ArtistCertificationState {
  artistCertification?: ArtistCertification;
  isLoading: boolean;
  isCreating: boolean;
  isRequestingReview: boolean;
}

const initialState: ArtistCertificationState = {
  isLoading: false,
  isCreating: false,
  isRequestingReview: false,
};

export const artistCertificationReducer = createReducer(
  initialState,
  on(ArtistCertificationActions.loadedArtistCertification, (state, action) => {
    return {
      ...state,
      isLoading: false,
      artistCertification: action.certification,
    };
  }),
  on(ArtistCertificationActions.createCertification, (state, _) => {
    return {
      ...state,
      isLoading: false,
      artistCertification: undefined,
      isCreating: true,
    };
  }),
  on(ArtistCertificationActions.createdCertification, (state, action) => {
    return {
      ...state,
      artistCertification: action.certification,
      isLoading: false,
      isCreating: false,
    };
  }),
  on(ArtistCertificationActions.requestReview, (state, _) => ({
    ...state,
    isRequestingReview: true,
  })),
  on(ArtistCertificationActions.reviewRequested, (state, action) => ({
    ...state,
    artistCertification: action.certification,
    isRequestingReview: false,
  })),
);
