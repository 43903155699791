import videojs, { VideoJsPlayer } from "video.js";

export interface RotateMirrorOptions {
  rotation: 0 | 90 | 180 | 270;
  mirrorVertically: boolean;
  mirrorHorizontally: boolean;
  applyToPoster: boolean;
}

export const NoRotateAndNoMirrorOptions = {
  mirrorHorizontally: false,
  mirrorVertically: false,
  rotation: 0,
  applyToPoster: false,
} as RotateMirrorOptions;

const Plugin = videojs.getPlugin("plugin");

export class RotateMirrorPlugin extends Plugin {
  private readonly cssTransformProperties = [
    "transform",
    "-webkit-transform",
    "-moz-transform",
  ];
  private readonly cssHelper = new CssHelper(this.cssTransformProperties);

  constructor(player: VideoJsPlayer) {
    super(player);

    this.player.on(
      "rotateMirror",
      (event: any, options: RotateMirrorOptions) => {
        // console.log(
        //   `rotateMirror: videoWidth: ${this.player.videoWidth()}, videoHeight: ${this.player.videoHeight()}, currentWidth: ${this.player.currentWidth()}, currentHeight: ${this.player.currentHeight()}`,
        // );

        const isHorizontalVideo = () => {
          return this.player.videoWidth() > this.player.videoHeight();
        };

        const isVerticalVideo = () => {
          return !isHorizontalVideo();
        };

        const isCurrentlyHorizontalVideo = () => {
          if (isHorizontalVideo()) {
            return options.rotation == 0 || options.rotation == 180;
          } else {
            return options.rotation == 90 || options.rotation == 270;
          }
        };

        const currentVideoDimensions = () => {
          const requiresFlip = () => {
            if (isCurrentlyHorizontalVideo() && isVerticalVideo()) {
              return true;
            }
            if (!isCurrentlyHorizontalVideo() && isHorizontalVideo()) {
              return true;
            }
            return false;
          };

          if (requiresFlip()) {
            return {
              width: this.player.videoHeight(),
              height: this.player.videoWidth(),
            } as videojs.Component.DimensionObject;
          } else {
            return {
              width: this.player.videoWidth(),
              height: this.player.videoHeight(),
            } as videojs.Component.DimensionObject;
          }
        };

        const calculateScaleFactor = () => {
          const relativeVideoSizeToDisplayAreaScale = () => {
            // DAFUQ: we should use the currentVideoDimensions().
            // However with the other messy stuff going on here it somehow cancels out and works in most cases!
            if (this.player.currentHeight() > this.player.videoHeight()) {
              return this.player.currentHeight() / this.player.videoHeight();
            } else {
              return this.player.videoHeight() / this.player.currentHeight();
            }
          };

          const scaleFactor = (
            video: videojs.Component.DimensionObject,
            display: videojs.Component.DimensionObject,
          ) => {
            const currentDimensions = currentVideoDimensions();
            const videoRatio =
              currentDimensions.width / currentDimensions.height;

            // in portrait: < 1
            // in landscape: > 1
            const displayRatio = display.width / display.height;

            if (player.isFullscreen()) {
              // when we have a rotation which is either 90 or 270 the scale factor must be different than 1(don't ask why )
              // when we have no rotation (or 180 degrees) we can let the scale factor be 1 (don't ask why)
              if (isCurrentlyHorizontalVideo()) {
                return 1;
              } else {
                // TODO: #70-2 as far as we can tell: only portrait mode display in portrait mode video does not work!
                // TODO: #70-2 LET IT BE FOR NOW!

                if (videoRatio >= displayRatio) {
                  return (
                    display.width /
                    currentDimensions.width /
                    relativeVideoSizeToDisplayAreaScale()
                  );
                } else {
                  return (
                    display.height /
                    currentDimensions.height /
                    relativeVideoSizeToDisplayAreaScale()
                  );
                }
              }
            } else if (video.width > 0 && video.height > 0) {
              if (videoRatio >= displayRatio) {
                // more landscape: fit width
                return (
                  (display.width / video.width) *
                  relativeVideoSizeToDisplayAreaScale() // XXX: no idea why..
                );
              } else {
                return (
                  (display.height / video.height) *
                  relativeVideoSizeToDisplayAreaScale() // XXX: no idea why..
                );
              }
            } else {
              return;
            }
          };

          const displayDimensions = this.player.currentDimensions();
          const videoDimensions = currentVideoDimensions();

          //console.log("display:");
          //console.log(displayDimensions);
          //console.log("video:");
          //console.log(videoDimensions);

          const scale = scaleFactor(videoDimensions, displayDimensions);
          //console.log(`scaleFactor: ${scale}`);
          return scale;
        };

        const requiresMirrorFlip =
          isHorizontalVideo() != isCurrentlyHorizontalVideo();

        const element = player.el();
        const video = element.getElementsByTagName("video")[0];
        const posters = element.getElementsByClassName("vjs-poster");
        const poster = posters.length > 0 ? posters[0] : undefined; // XXX: we should enable/disable this feature? - we would rotate the tumbnail aka (poster) when we have A: audio only, B: video

        const rotate = options.rotation ? `rotate(${options.rotation}deg)` : "";

        const mirrorHorizontally = options.mirrorHorizontally
          ? requiresMirrorFlip
            ? "scaleY(-1)"
            : "scaleX(-1)"
          : "";

        const mirrorVertically = options.mirrorVertically
          ? requiresMirrorFlip
            ? "scaleX(-1)"
            : "scaleY(-1)"
          : "";

        const scaleFactor = calculateScaleFactor();
        const scale = scaleFactor ? `scale(${scaleFactor})` : "";

        const cssTransform = ` ${mirrorHorizontally} ${mirrorVertically} ${scale}  ${rotate}`;

        const style = this.cssTransformProperties
          .map((transformProperty) => `${transformProperty}: ${cssTransform}`)
          .join(";");

        //console.log(`rotate-mirror: style=${style}`);

        video.setAttribute(
          "style",
          this.cssHelper.merge(video.getAttribute("style") || "", style),
        );
        if (options.applyToPoster) {
          poster?.setAttribute(
            "style",
            this.cssHelper.merge(poster?.getAttribute("style") || "", style),
          );
        } else {
          poster?.setAttribute(
            "style",
            this.cssHelper.merge(poster?.getAttribute("style") || "", ""), // reset
          );
        }

        element.setAttribute("style", "overflow: hidden");
      },
    );
  }
}
export function registerRotateMirror() {
  if (!videojs.getPlugin("rotateMirror")) {
    videojs.registerPlugin("rotateMirror", RotateMirrorPlugin);
  }
}

declare module "video.js" {
  // tell the type system our plugin method exists...
  export interface VideoJsPlayer {
    rotateMirror: () => RotateMirrorPlugin;
  }
  // tell the type system our plugin options exist...
  export interface VideoJsPlayerPluginOptions {
    examplePlugin?: Partial<RotateMirrorOptions>;
  }
}

class CssHelper {
  constructor(private readonly propertyNamesToMerge: string[]) {}

  merge(existingStyle: string, newProperties: string) {
    const existingProperties = (existingStyle || "").split(";");

    return existingProperties
      .filter(
        (p) => !this.propertyNamesToMerge.some((name) => p.includes(name)),
      )
      .join(";")
      .concat(newProperties);
  }
}
