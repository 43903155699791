import { ActionReducer, MetaReducer } from "@ngrx/store";
import { localStorageSync } from "ngrx-store-localstorage";
import { UsedResourcesForUser } from "../../models/user/used-resources-for-user";
import {
  artistCertificationReducer,
  ArtistCertificationState,
} from "./artist-certification.reducer";
import {
  artistProfileReducer,
  ArtistProfileState,
} from "./artist-profile.reducer";
import {
  editMusicPieceReducer,
  EditMusicPieceState,
} from "./edit-music-piece.reducer";
import { licenseReducer, LicenseState } from "./license.reducer";
import { markerReducer, MarkerState } from "./marker.reducer";
import { metronomeReducer, MetronomeState } from "./metronome.reducer";
import { musicPieceReducer, MusicPieceState } from "./music-piece.reducer";
import { playerReducer, PlayerState } from "./player.reducer";
import {
  recordingPartGroupReducer,
  RecordingPartGroupState,
} from "./recording-part-group.reducer";
import { registrationReducer, RegistrationState } from "./registration.reducer";
import { rotationReducer, RotationState } from "./rotation.reducer";
import { usedResourcesForUserReducer } from "./used-resources-for-user.reducer";
import {
  usedResourcesReducer,
  UsedResourcesState,
} from "./used-resources.reducer";
import * as EditMusicPieceSelectors from "./edit-music-piece.reducer";
import * as UserSelectors from "./user.reducer";
import { userReducer, UserState } from "./user.reducer";
import { purchaseReducer, PurchaseState } from "./purchase.reducer";

import * as PlayerSelectors from "./player.reducer";
import { salesReducer, SalesState } from "./sales.reducer";
import { CollectionState, collectionReducer } from "./collection.reducer";
import {
  EditCollectionState,
  editCollectionReducer,
} from "./edit-collection.reducer";
import { CollectionSelectors, EditCollectionSelectors } from "../selectors";
import { PlaybackState, playbackReducer } from "./playback.reducer";
import {
  ReadonlyCollectionState,
  readonlyCollectionReducer,
} from "./readonly-collection.reducer";
import { ExploreState, exploreReducer } from "./explore.reducer";
import { FaqState, faqReducer } from "./faq.reducer";
import {
  NotificationSettingsState,
  notificationSettingsReducer,
} from "./notification-settings.reducer";
import { notificationReducer, NotificationState } from "./notification.reducer";
import { playlistReducer, PlaylistState } from "./playlist.reducer";
import {
  practiceRoutinesReducer,
  PracticeRoutinesState,
} from "./practice-routines.reducer";
import {
  practiceRoutineReducer,
  PracticeRoutineState,
} from "./practice-routine.reducer";
import {
  videoFeedbacksReducer,
  VideoFeedbacksState,
} from "./video-feedbacks.reducer";
import {
  videoFeedbackReducer,
  VideoFeedbackState,
} from "./video-feedback.reducer";

export const REDUCERS = {
  registration: registrationReducer,
  usedResources: usedResourcesReducer,
  usedResourcesForUser: usedResourcesForUserReducer,
  license: licenseReducer,
  player: playerReducer,
  marker: markerReducer,
  recordingPartGroups: recordingPartGroupReducer,
  metronome: metronomeReducer,
  rotation: rotationReducer,
  musicPiece: musicPieceReducer,
  artistCertification: artistCertificationReducer,
  artistProfile: artistProfileReducer,
  editMusicPiece: editMusicPieceReducer,
  user: userReducer,
  purchase: purchaseReducer,
  sales: salesReducer,
  collection: collectionReducer,
  editCollection: editCollectionReducer,
  playback: playbackReducer,
  readonlyCollection: readonlyCollectionReducer,
  explore: exploreReducer,
  faq: faqReducer,
  notificationSettings: notificationSettingsReducer,
  notification: notificationReducer,
  playlist: playlistReducer,
  practiceRoutines: practiceRoutinesReducer,
  practiceRoutine: practiceRoutineReducer,
  videoFeedbacks: videoFeedbacksReducer,
  videoFeedback: videoFeedbackReducer,
};

export interface AppState {
  registration: RegistrationState;
  usedResources: UsedResourcesState;
  usedResourcesForUser: UsedResourcesForUser;
  license: LicenseState;
  player: PlayerState;
  marker: MarkerState;
  recordingPartGroups: RecordingPartGroupState;
  metronome: MetronomeState;
  rotation: RotationState;
  musicPiece: MusicPieceState;
  artistCertification: ArtistCertificationState;
  artistProfile: ArtistProfileState;
  editMusicPiece: EditMusicPieceState;
  user: UserState;
  purchase: PurchaseState;
  sales: SalesState;
  collection: CollectionState;
  editCollection: EditCollectionState;
  readonlyCollection: ReadonlyCollectionState;
  playback: PlaybackState;
  explore: ExploreState;
  faq: FaqState;
  notificationSettings: NotificationSettingsState;
  notification: NotificationState;
  playlist: PlaylistState;
  practiceRoutines: PracticeRoutinesState;
  practiceRoutine: PracticeRoutineState;
  videoFeedbacks: VideoFeedbacksState;
  videoFeedback: VideoFeedbackState;
}

export function localStorageSyncReducer(
  reducer: ActionReducer<any>,
): ActionReducer<any> {
  return localStorageSync({
    keys: [
      "license",
      "usedResourcesForUser",
      "artistCertification",
      "user",
      {
        notificationSettings: [
          "serverSettings",
          "userSettings",
          "pushNotificationSubscription",
        ],
      },
    ],
    rehydrate: true,
  })(reducer);
}

export const META_REDUCERS: Array<MetaReducer<any, any>> = [
  localStorageSyncReducer,
];

export {
  EditMusicPieceSelectors,
  UserSelectors,
  PlayerSelectors,
  CollectionSelectors,
  EditCollectionSelectors,
};
