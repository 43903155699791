import { createReducer, createSelector, on } from "@ngrx/store";
import { RecordingPartGroup } from "src/app/models/recording-part-group/recording-part-group";
import { RecordingPart } from "src/app/models/recording-part/recording-part";
import { AppState } from ".";
import { PlayerActions, RecordingPartGroupActions } from "../actions";

export interface RecordingPartGroupState {
  recordingPartGroups: RecordingPartGroup[];
  selectedRecordingPartGroup?: RecordingPartGroup;
  recordingParts: RecordingPart[];
}

const initialState = {
  recordingPartGroups: [],
  recordingParts: [],
} as RecordingPartGroupState;

export const recordingPartGroupReducer = createReducer(
  initialState,
  on(RecordingPartGroupActions.loadedRecordingPartGroups, (state, action) => {
    return {
      ...state,
      recordingPartGroups: action.recordingPartGroups,
      selectedRecordingPartGroup: undefined,
    };
  }),
  on(PlayerActions.openPlayerAction, (_) => initialState),
  on(RecordingPartGroupActions.selectRecordingPartGroup, (state, action) => {
    return {
      ...state,
      selectedRecordingPartGroup: action.recordingPartGroup,
      isInDrawingMode: false,
    };
  }),

  on(RecordingPartGroupActions.loadedRecordingParts, (state, action) => {
    return {
      ...state,
      recordingParts: action.recordingParts,
    };
  }),
  on(RecordingPartGroupActions.addedRecordingPartGroup, (state, action) => {
    return {
      ...state,
      recordingPartGroups: [
        ...state.recordingPartGroups,
        action.recordingPartGroup,
      ],
    };
  }),
  on(RecordingPartGroupActions.addedRecordingPart, (state, action) => {
    return {
      ...state,
      recordingParts: [...state.recordingParts, action.recordingPart],
    };
  }),
  on(RecordingPartGroupActions.deletedRecordingPartGroup, (state, action) => {
    return {
      ...state,
      markers: [],
      recordingParts: [],
      recordingPartGroups: state.recordingPartGroups.filter(
        (rp) => rp.name !== action.recordingPartGroup.name
      ),
    };
  }),
  on(RecordingPartGroupActions.deleteRecordingPart, (state, action) => {
    // optimistic
    return {
      ...state,
      recordingParts: [
        ...state.recordingParts.filter(
          (rp) => rp.name != action.recordingPart.name
        ),
      ],
    };
  }),
  on(RecordingPartGroupActions.updateRecordingPart, (state, action) => {
    return {
      ...state,
      recordingParts: [
        ...state.recordingParts.filter(
          (rp) => rp.name != action.recordingPart.name
        ),
        action.recordingPart,
      ],
    };
  })
);

export const selectRecordingPartGroups = createSelector(
  (state: AppState) => state.recordingPartGroups.recordingPartGroups,
  (groups) => groups
);

export const selectSelectedRecordingPartGroup = createSelector(
  (state: AppState) => state.recordingPartGroups.selectedRecordingPartGroup,
  (group) => group
);

export const selectRecordingParts = createSelector(
  (state: AppState) => state.recordingPartGroups.recordingParts,
  (parts) => parts
);
