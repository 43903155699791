import { createReducer, on, createSelector } from "@ngrx/store";
import { AppState } from ".";
import { usedResourcesLoadedSuccessfullyAction } from "../actions/used-resources-for-user.actions";
import { elapsedPracticeTimeAction } from "../actions/used-resources.actions";
import { UsedResourcesForUser } from "../../models/user/used-resources-for-user";

const initialState: UsedResourcesForUser = {
  numberOfPiecesThisYear: 0,
  practiceDurationThisMonthMs: 0,
  totalStorageBytes: 0,
  totalCollections: 0,
  totalPracticeRoutines: 0,
};

export const usedResourcesForUserReducer = createReducer(
  initialState,
  on(usedResourcesLoadedSuccessfullyAction, (state, data) => {
    return {
      ...state,
      ...data,
    };
  }),
  on(elapsedPracticeTimeAction, (state, props) => {
    return {
      ...state,
      practiceDurationThisMonthMs:
        state.practiceDurationThisMonthMs + props.practiceTime,
    };
  }),
);
