import { createReducer, on } from "@ngrx/store";

import { PlaylistItem } from "src/app/models/player/playlist";
import { PlaylistType } from "../actions/playlist.actions";
import { PlayerActions, PlaylistActions } from "../actions";

export interface PlaylistState {
  currentPlaylistItemName?: string;
  playlistItems: PlaylistItem[];
  playlist: string;
  playlistType: PlaylistType;
  isLoadingPlaylist: boolean;
}

const initialState = {
  isLoadingPlaylist: false,
  playlistItems: [],
  playlist: "",
  playlistType: "collection",
} as PlaylistState;

export const playlistReducer = createReducer(
  initialState,
  on(PlaylistActions.loadPlaylist, (state, action) => ({
    ...state,
    isLoadingPlaylist: true,
    // we don't delete the old data as we can use it as cache!
  })),
  on(PlaylistActions.loadedPlaylist, (state, action) => ({
    ...state,
    isLoadingPlaylist: false,
    playlist: action.playlist,
    playlistItems: action.playlistItems,
    playlistType: action.playlistType,
  })),
  on(PlayerActions.openPlayerAction, (state, action) => ({
    ...initialState,
    playlistItems: state.playlistItems,
    currentPlaylistItemName: action.name,
  })),
);
