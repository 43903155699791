import { createReducer, createSelector, on } from "@ngrx/store";
import { Rotation } from "src/app/models/rotation/rotation";
import { NoRotateAndNoMirrorOptions } from "src/app/videojs-plugin/rotate-mirror-plugin";
import { AppState } from ".";
import { PlayerActions, RotationActions } from "../actions";

export interface RotationState {
  rotation?: Rotation;
}

const initialState = {} as RotationState;

export const rotationReducer = createReducer(
  initialState,
  on(PlayerActions.openPlayerAction, () => initialState),
  on(RotationActions.loadedRotation, (state, action) => {
    return {
      ...state,
      rotation: action.rotation,
    };
  }),
  on(RotationActions.storeRotation, (state, action) => {
    // we are optimistic
    return {
      ...state,
      rotation: action.rotation,
    };
  }),
  on(RotationActions.storedRotation, (state, action) => {
    // we are optimistic, but we have to update the rotation when no "name"
    // was set before (e.g  on first save)
    return {
      ...state,
      rotation: action.rotation,
    };
  }),
);

export const selectRotation = createSelector(
  (state: AppState) => state.rotation.rotation,
  (rotation) =>
    rotation ??
    ({
      mirrorHorizontal: NoRotateAndNoMirrorOptions.mirrorHorizontally,
      mirrorVertical: NoRotateAndNoMirrorOptions.mirrorVertically,
      rotation: NoRotateAndNoMirrorOptions.rotation,
    } as Rotation),
);
