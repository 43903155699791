import { createReducer, on } from "@ngrx/store";
import { StoredCollection } from "src/app/models/collection/stored-collection";
import { CollectionActions, EditCollectionActions } from "../actions";
import { StoredLinkedCollection } from "src/app/models/collection/stored-linked-collection";
import { StoredCollectionItem } from "src/app/models/collection/stored-collection-item";
import { StoredLinkedCollectionItem } from "src/app/models/collection/stored-linked-collection-item";

export interface CollectionState {
  isLoadingCollections: boolean;
  collections: StoredCollection[];
  filteredCollections: StoredCollection[];
  filteredLinkedCollections: StoredLinkedCollection[];
  filteredCollectionsForLinkedCollections: StoredCollection[];
  isDeletingCollection: boolean;
  isLoadingLinkedCollections: boolean;
  linkedCollections: StoredLinkedCollection[];
  collectionsForLinkedCollections: StoredCollection[];
  collectionItems: StoredCollectionItem[];
  linkedCollectionItems: StoredLinkedCollectionItem[];
  isLoadingLinkedCollectionItems: boolean;
  isLoadingCollectionItems: boolean;
}

const initialState = {
  isLoadingCollections: false,
  collections: [],
  filteredCollections: [],
  isDeletingCollection: false,
  isLoadingLinkedCollections: false,
  collectionsForLinkedCollections: [],
  linkedCollections: [],
  filteredCollectionsForLinkedCollections: [],
  filteredLinkedCollections: [],
  collectionItems: [],
  linkedCollectionItems: [],
  isLoadingLinkedCollectionItems: false,
  isLoadingCollectionItems: false,
} as CollectionState;

export const collectionReducer = createReducer(
  initialState,
  on(CollectionActions.loadCollections, (state, _) => {
    return {
      ...state,
      isLoadingCollections: true,
      collections: [],
    };
  }),
  on(CollectionActions.loadedCollections, (state, action) => {
    return {
      ...state,
      isLoadingCollections: false,
      collections: action.collections,
    };
  }),
  on(CollectionActions.filtered, (state, action) => {
    return {
      ...state,
      filteredCollections: action.filteredCollections,
      filteredCollectionsForLinkedCollections:
        action.filteredCollectionsForLinkedCollections,
      filteredLinkedCollections: action.filteredLinkedCollections,
    };
  }),
  on(EditCollectionActions.deleteCollection, (state, action) => {
    return {
      ...state,
      isDeletingCollection: true,
      collections: state.collections.filter(
        // we are optimistic
        (c) => c.name != action.collection.name
      ),
      filteredCollections: state.filteredCollections.filter(
        (c) => c.name != action.collection.name
      ),
    };
  }),
  on(EditCollectionActions.deletedCollection, (state, action) => {
    return {
      ...state,
      isDeletingCollection: false,
    };
  }),
  on(CollectionActions.loadLinkedCollections, (state, _) => {
    return {
      ...state,
      isLoadingLinkedCollections: true,
    };
  }),
  on(
    CollectionActions.loadedCollectionsForLinkedCollections,
    (state, action) => {
      return {
        ...state,
        isLoadingCollections: false,
        linkedCollections: action.linkedCollections,
        collectionsForLinkedCollections: action.collectionsForLinkedCollections,
      };
    }
  ),
  on(CollectionActions.loadLinkedCollectionItems, (state, action) => {
    return {
      ...state,
      linkedCollectionItems: [],
      isLoadingLinkedCollectionItems: true,
    };
  }),
  on(CollectionActions.loadedLinkedCollectionItems, (state, action) => {
    return {
      ...state,
      linkedCollectionItems: action.linkedCollectionItems,
      isLoadingLinkedCollectionItems: false,
    };
  }),
  on(CollectionActions.loadCollectionItems, (state, action) => {
    return {
      ...state,
      collectionItems: [],
      isLoadingCollectionItems: true,
    };
  }),
  on(CollectionActions.loadedCollectionItems, (state, action) => {
    return {
      ...state,
      collectionItems: action.collectionItems,
      isLoadingCollectionItems: false,
    };
  })
);
