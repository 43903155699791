import { createReducer, on } from "@ngrx/store";
import { NotificationActions, NotificationSettingsActions } from "../actions";
import { NotificationTrigger } from "src/app/models/notification/notification-trigger";

export interface NotificationState {
  isLoadingNotifications: boolean;
  notifications: NotificationTrigger[];
  isMarkingAsRead: boolean;
  isMarkingAllAsRead: boolean;
}

const initialState = {
  isLoadingNotifications: false,
  notifications: [],
  isMarkingAsRead: false,
  isMarkingAllAsRead: false,
} as NotificationState;

export const notificationReducer = createReducer(
  initialState,
  on(NotificationActions.loadNotifications, (state, _) => ({
    ...state,
    isLoadingNotifications: true,
  })),
  on(NotificationActions.loadedNotifications, (state, action) => ({
    ...state,
    isLoadingNotifications: false,
    notifications: action.notifications,
  })),
  on(NotificationActions.markAsRead, (state, _) => ({
    ...state,
    isMarkingAsRead: true,
  })),
  on(NotificationActions.markedAsRead, (state, action) => ({
    ...state,
    isMarkingAsRead: false,
    notifications: [
      ...state.notifications.filter((n) => n.name != action.notification.name),
      action.notification,
    ],
  })),
  on(NotificationActions.markAllAsRead, (state, _) => ({
    ...state,
    isMarkingAllAsRead: true,
  })),
  on(NotificationActions.markedAllAsRead, (state, _) => ({
    ...state,
    isMarkingAllAsRead: false,
    notifications: state.notifications.map((n) => ({ ...n, read: true })),
  })),
);
