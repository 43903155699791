import { createReducer, on } from "@ngrx/store";
import { UserNotificationSettings } from "src/app/models/notification/user-notification-settings";
import { ServerPushNotificationSettings } from "src/app/models/notification/server-push-notification-settings";
import { NotificationSettingsActions } from "../actions";
import { PushNotificationSubscription } from "src/app/models/notification/push-subscription";

export interface NotificationSettingsState {
  isLoadingServerSettings: boolean;
  serverSettings?: ServerPushNotificationSettings;
  userSettings?: UserNotificationSettings;
  isLoadingUserSettings: boolean;
  isSavingUserSettings: boolean;
  activeSubscription?: PushSubscription;
  isRegisteringSubscriptionInBrowser: boolean;
  pushNotificationSubscription?: PushNotificationSubscription;
  isCreatingPushNotificationSubscription: boolean;
}

const initialState = {
  isLoadingServerSettings: false,
  isLoadingUserSettings: false,
  isSavingUserSettings: false,
  isRegisteringSubscriptionInBrowser: false,
} as NotificationSettingsState;

export const notificationSettingsReducer = createReducer(
  initialState,
  on(NotificationSettingsActions.loadServerSettings, (state, _) => ({
    ...state,
    isLoadingServerSettings: true,
  })),
  on(NotificationSettingsActions.loadedServerSettings, (state, action) => ({
    ...state,
    isLoadingServerSettings: false,
    serverSettings: action.settings,
  })),
  on(NotificationSettingsActions.loadUserSettings, (state, _) => ({
    ...state,
    isLoadingUserSettings: true,
    userSettings: undefined,
  })),
  on(NotificationSettingsActions.loadedUserSettings, (state, action) => ({
    ...state,
    isLoadingUserSettings: false,
    userSettings: action.settings,
  })),
  on(NotificationSettingsActions.saveUserSettings, (state, action) => ({
    ...state,
    isSavingUserSettings: true,
  })),
  on(NotificationSettingsActions.savedUserSettings, (state, action) => ({
    ...state,
    isSavingUserSettings: false,
    userSettings: action.settings,
  })),
  on(
    NotificationSettingsActions.tryRegisterPushSubscription,
    (state, action) => ({
      ...state,
      isRegisteringSubscriptionInBrowser: true,
    }),
  ),
  on(NotificationSettingsActions.updatedPushSubscription, (state, action) => ({
    ...state,
    activeSubscription: action.subscription,
    isRegisteringSubscriptionInBrowser:
      state.isRegisteringSubscriptionInBrowser &&
      action.subscription == undefined,
  })),
  on(
    NotificationSettingsActions.registerPushSubscriptionFailed,
    (state, _) => ({
      ...state,
      isRegisteringSubscriptionInBrowser: false,
    }),
  ),
  on(NotificationSettingsActions.createPushSubscription, (state, action) => ({
    ...state,
    pushNotificationSubscription: undefined,
    isCreatingPushNotificationSubscription: true,
  })),
  on(NotificationSettingsActions.createdPushSubscription, (state, action) => ({
    ...state,
    isCreatingPushNotificationSubscription: false,
    pushNotificationSubscription: action.subscription,
  })),
  on(
    NotificationSettingsActions.createPushSubscriptionFailed,
    (state, action) => ({
      ...state,
      pushNotificationSubscription: undefined,
      isCreatingPushNotificationSubscription: false,
    }),
  ),
  on(NotificationSettingsActions.deletePushSubscription, (state, action) => ({
    ...state,
    pushNotificationSubscription: undefined,
  })),
);
