import { createReducer, on } from "@ngrx/store";
import { PlaybackActions } from "../actions";

export interface PlaybackState {
  currentlyPlayingUrl: string;
}

const initialState = {} as PlaybackState;

export const playbackReducer = createReducer(
  initialState,
  on(PlaybackActions.play, (state, action) => {
    return {
      ...state,
      currentlyPlayingUrl: action.playingUrl,
    };
  })
);
