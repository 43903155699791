import { createReducer, on } from "@ngrx/store";
import { ExploreActions } from "../actions";
import { ExploreArtistProfile } from "src/app/models/explore/explore-artist-profile";
import { Preview } from "src/app/models/music-piece/preview";
import { ExploreCollection } from "src/app/models/explore/explore-collection";
import { ExploreMusicPiece } from "src/app/models/explore/explore-music-piece";

export interface ExploreState {
  items: ExploreArtistProfile[];
  isLoadingItems: boolean;
  partnerSearchResults: ExploreArtistProfile[];
  listedMusicPiecesSearchResults: ExploreMusicPiece[];
  listedCollectionsSearchResults: ExploreCollection[];
  isLoadingPartnerSearchResults: boolean;
  isLoadingListedCollectionSearchResults: boolean;
  isLoadingListedMusicPiecesSearchResults: boolean;
  isLoadingPreviewsForListedMusicPiecesSearchResults: boolean;
  previewsForListedMusicPiecesSearchResults: Preview[];
  query: string;
}

const initialState = {
  items: [],
  isLoadingItems: false,
  isLoadingPartnerSearchResults: false,
  isLoadingListedCollectionSearchResults: false,
  isLoadingListedMusicPiecesSearchResults: false,
  previewsForListedMusicPiecesSearchResults: [],
  isLoadingPreviewsForListedMusicPiecesSearchResults: false,
  listedCollectionsSearchResults: [],
  listedMusicPiecesSearchResults: [],
  partnerSearchResults: [],
  query: "",
} as ExploreState;

export const exploreReducer = createReducer(
  initialState,
  on(ExploreActions.load, (state, _) => ({
    ...initialState,
    isLoadingItems: true,
  })),
  on(ExploreActions.loaded, (state, action) => ({
    ...state,
    items: action.items,
    isLoadingItems: false,
  })),
  on(ExploreActions.search, (state, action) => ({
    ...state,
    isLoadingListedCollectionSearchResults: true,
    isLoadingListedMusicPiecesSearchResults: true,
    isLoadingPartnerSearchResults: true,
    isLoadingPreviewsForListedMusicPiecesSearchResults: true,
    previewsForListedMusicPiecesSearchResults: [],
    listedCollectionsSearchResults: [],
    partnerSearchResults: [],
    items: [],
    query: action.query,
  })),
  on(ExploreActions.searchedPartners, (state, action) => ({
    ...state,
    isLoadingPartnerSearchResults: false,
    partnerSearchResults: action.searchResults,
  })),
  on(ExploreActions.searchedListedMusicPieces, (state, action) => ({
    ...state,
    isLoadingListedMusicPiecesSearchResults: false,
    listedMusicPiecesSearchResults: action.searchResults,
  })),
  on(ExploreActions.searchedListedCollections, (state, action) => ({
    ...state,
    isLoadingListedCollectionSearchResults: false,
    listedCollectionsSearchResults: action.searchResults,
  })),
  on(
    ExploreActions.loadedSearchedListedMusicPiecePreviews,
    (state, action) => ({
      ...state,
      isLoadingPreviewsForListedMusicPiecesSearchResults: false,
      previewsForListedMusicPiecesSearchResults: action.previews,
    }),
  ),
);
