import { createReducer, on } from "@ngrx/store";
import { SalesActions } from "../actions";
import { PurchasedItem } from "src/app/models/purchase/purchased-item";
import { CreditStatement } from "src/app/models/sales/credit-statement";
import { CreditPayout } from "src/app/models/sales/credit-payout";

export interface SalesState {
  sales: PurchasedItem[];
  creditStatements: CreditStatement[];
  isLoadingSales: boolean;
  isLoadingCreditStatements: boolean;
  creditPayouts: CreditPayout[];
  isLoadingCreditPayouts: boolean;
}

const initialState = {
  sales: [],
  isLoadingSales: false,
  creditStatements: [],
  isLoadingCreditStatements: false,
  isLoadingCreditPayouts: false,
  creditPayouts: [],
} as SalesState;

export const salesReducer = createReducer(
  initialState,
  on(SalesActions.loadSales, (state, _) => {
    return {
      ...state,
      sales: [],
      isLoadingSales: true,
    };
  }),
  on(SalesActions.loadedSales, (state, action) => {
    return {
      ...state,
      isLoadingSales: false,
      sales: action.sales,
    };
  }),
  on(SalesActions.loadCreditStatements, (state, _) => ({
    ...state,
    isLoadingCreditStatements: true,
    creditStatements: [],
    creditPayouts: [],
    isLoadingCreditPayouts: true,
  })),
  on(SalesActions.loadedCreditStatements, (state, action) => ({
    ...state,
    creditStatements: action.creditStatements,
    isLoadingCreditStatements: false,
  })),
  on(SalesActions.loadedCreditPayouts, (state, action) => ({
    ...state,
    creditPayouts: action.creditPayouts,
    isLoadingCreditPayouts: false,
  })),
);
