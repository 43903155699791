import { createReducer, on } from "@ngrx/store";
import { licenseLoadedSuccessfullyAction } from "../actions/license.actions";
import {
  emptyUserLicense,
  License,
  UserLicense,
} from "../../models/user/user-license";
import { LicenseActions } from "../actions";
import { CheckoutSession } from "src/app/models/license/checkout-session";

export interface LicenseState {
  userLicense: UserLicense;
  isLoadingFallbackAndUpgrade: boolean;
  fallback?: License;
  upgrade?: License;
  checkoutSession?: CheckoutSession;
}

const initialState = {
  userLicense: emptyUserLicense,
  isLoadingFallbackAndUpgrade: false,
} as LicenseState;

export const licenseReducer = createReducer(
  initialState,
  on(licenseLoadedSuccessfullyAction, (state, ul) => {
    return {
      ...state,
      userLicense: ul,
    };
  }),
  on(LicenseActions.loadFallbackAndUpgrade, (state, _) => {
    return {
      ...state,
      isLoadingFallbackAndUpgrade: true,
      fallback: undefined,
      upgrade: undefined,
    };
  }),
  on(LicenseActions.loadedFallbackAndUpgrade, (state, action) => {
    return {
      ...state,
      isLoadingFallbackAndUpgrade: false,
      fallback: action.fallback,
      upgrade: action.upgrade,
    };
  }),
  on(LicenseActions.checkCheckoutSessionStatus, (state, action) => {
    return {
      ...state,
      checkoutSession: undefined,
    };
  }),
  on(LicenseActions.checkoutSessionStatusReceived, (state, action) => {
    console.log("checkoutSessionStatusReceived", action);
    return {
      ...state,
      checkoutSession: action.checkoutSession,
    };
  }),
);
