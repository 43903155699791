import { createReducer, on } from "@ngrx/store";
import { FaqActions } from "../actions";
import { FaqItem } from "src/app/models/faq/faq-item";

export interface FaqState {
  items: FaqItem[];
  isLoadingItems: boolean;
  searchResults: FaqItem[];
  isLoadingSearchResults: boolean;
  query: string;
}

const initialState = {
  items: [],
  isLoadingItems: false,
  isLoadingSearchResults: false,
  searchResults: [],
  query: "",
} as FaqState;

export const faqReducer = createReducer(
  initialState,
  on(FaqActions.load, (state, _) => ({
    ...state,
    isLoadingItems: true,
    items: [],
  })),
  on(FaqActions.loaded, (state, action) => ({
    ...state,
    items: action.items,
    isLoadingItems: false,
  })),
  on(FaqActions.search, (state, action) => ({
    ...state,
    query: action.query,
    isLoadingSearchResults: true,
  })),
  on(FaqActions.searched, (state, action) => ({
    ...state,
    isLoadingSearchResults: false,
    searchResults: action.searchResults,
  })),
);
