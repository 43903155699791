import { createReducer, on } from "@ngrx/store";
import { MusicPiece } from "src/app/models/music-piece/music-piece";
import { Preview } from "src/app/models/music-piece/preview";
import { LinkedMusicPieceWithMusicPiece } from "src/app/services/linked-music-piece/linked-music-piece.service";
import { EditMusicPieceActions, MusicPieceActions } from "../actions";
import { MusicPieceToDisplay } from "src/app/models/music-piece/music-piece-to-display";

export interface MusicPieceState {
  musicPieces: MusicPiece[];
  linkedMusicPieces: LinkedMusicPieceWithMusicPiece[];
  isLoadingMusicPieces: boolean;
  isLoadingLinkedMusicPieces: boolean;
  musicPiecePreviews: Preview[];
  linkedMusicPiecePreviews: Preview[];
  filteredMusicPieces: MusicPieceToDisplay[];
}

const initialState = {
  musicPieces: [],
  linkedMusicPieces: [],
  isLoadingLinkedMusicPieces: false,
  isLoadingMusicPieces: false,
  linkedMusicPiecePreviews: [],
  musicPiecePreviews: [],
  filteredMusicPieces: [],
} as MusicPieceState;

export const musicPieceReducer = createReducer(
  initialState,
  on(MusicPieceActions.loadMusicPieces, (_) => {
    return {
      ...initialState,
      isLoadingLinkedMusicPieces: true,
      isLoadingMusicPieces: true,
      isLoadingPurchasedMusicPieces: true,
    };
  }),
  on(MusicPieceActions.loadedLinkedMusicPieces, (state, action) => {
    return {
      ...state,
      isLoadingLinkedMusicPieces: false,
      linkedMusicPieces: action.linkedMusicPieces,
    };
  }),
  on(MusicPieceActions.loadedMusicPieces, (state, action) => {
    return {
      ...state,
      isLoadingMusicPieces: false,
      musicPieces: action.musicPieces,
    };
  }),
  on(EditMusicPieceActions.deleteMusicPiece, (state, action) => {
    // we are optimistic: we remove the item from state as soon as we try to delete
    return {
      ...state,
      musicPieces: [
        ...state.musicPieces.filter((m) => m.name != action.musicPiece?.name),
      ],
      linkedMusicPieces: [
        ...state.linkedMusicPieces.filter(
          (lmp) => lmp.linkedMusicPiece?.name != action.linkedMusicPiece?.name,
        ),
      ],
    };
  }),
  on(MusicPieceActions.loadedMusicPiecePreviews, (state, action) => {
    return {
      ...state,
      musicPiecePreviews: action.previews,
    };
  }),
  on(MusicPieceActions.loadedLinkedMusicPiecePreviews, (state, action) => {
    return {
      ...state,
      linkedMusicPiecePreviews: action.previews,
    };
  }),
  on(MusicPieceActions.filteredMusicPieces, (state, action) => {
    return {
      ...state,
      filteredMusicPieces: action.filteredMusicPieces,
    };
  }),
);
