import { createReducer, on } from "@ngrx/store";
import { EditCollectionActions } from "../actions";
import { StoredCollection } from "src/app/models/collection/stored-collection";
import { StoredCollectionItem } from "src/app/models/collection/stored-collection-item";
import { MusicPieceToDisplay } from "src/app/models/music-piece/music-piece-to-display";
import { CollectionItemToDisplay } from "src/app/models/collection/collection-item-to-display";
import { StoredLinkedCollection } from "src/app/models/collection/stored-linked-collection";
import { StoredCollectionListing } from "src/app/models/collection/stored-collection-listing";

export interface EditCollectionState {
  isNew: boolean;
  name?: string;
  isLoadingCollection: boolean;
  collection?: StoredCollection;
  isSaving: boolean;
  collectionItems: StoredCollectionItem[];
  filteredCollectionItems: CollectionItemToDisplay[];
  isLoadingCollectionItems: boolean;
  isLoadingCollectionsItemsToAdd: boolean;
  collectionItemsToAdd: MusicPieceToDisplay[]; // should be renamed to: musicPiecesToAdd as they are not yet collection items
  filteredCollectionItemsToAdd: MusicPieceToDisplay[]; // should be renamed to: filteredMusicPiecesToAdd as they are not yet collection items
  isAddingCollectionItem: boolean;
  isRemovingCollectionItem: boolean;
  isUpdatingCollectionItems: boolean;
  isSharing: boolean;
  isLoadingLinkedCollections: boolean;
  linkedCollections: StoredLinkedCollection[];
  listing?: StoredCollectionListing;
  isLoadingCollectionListing: boolean;
  isSavingCollectionListing: boolean;
}

const initialState = {
  isNew: false,
  name: "",
  isLoadingCollection: false,
  isSaving: false,
  isLoadingCollectionItems: false,
  collectionItems: [],
  isLoadingCollectionsItemsToAdd: false,
  collectionItemsToAdd: [],
  filteredCollectionItems: [],
  filteredCollectionItemsToAdd: [],
  isAddingCollectionItem: false,
  isRemovingCollectionItem: false,
  isUpdatingCollectionItems: false,
  isSharing: false,
  linkedCollections: [],
  isLoadingLinkedCollections: false,
  isLoadingCollectionListing: false,
  isSavingCollectionListing: false,
} as EditCollectionState;

export const editCollectionReducer = createReducer(
  initialState,
  on(EditCollectionActions.openCollectionForEdit, (state, action) => {
    return {
      ...initialState,
      isLoadingCollection: action.isNew ? false : true,
      isNew: action.isNew,
      name: action.name,
      isLoadingCollectionItems: action.isNew ? false : true,
    };
  }),
  on(EditCollectionActions.loadedCollection, (state, action) => {
    return {
      ...state,
      isLoadingCollection: false,
      collection: action.collection,
    };
  }),
  on(EditCollectionActions.saveCollection, (state, action) => {
    return {
      ...state,
      isSaving: true,
    };
  }),
  on(EditCollectionActions.savedCollection, (state, action) => {
    return {
      ...state,
      isSaving: false,
      isNew: false,
      name: action.collection.name,
      collection: action.collection,
    };
  }),
  on(EditCollectionActions.saveCollectionFailed, (state, action) => {
    return {
      ...state,
      isSaving: false,
    };
  }),
  on(EditCollectionActions.loadedCollectionItems, (state, action) => {
    return {
      ...state,
      isLoadingCollectionItems: false,
      collectionItems: action.collectionItems,
    };
  }),
  on(EditCollectionActions.loadCollectionItemsToAdd, (state, _) => {
    return {
      ...state,
      isLoadingCollectionsItemsToAdd: true,
      collectionItemsToAdd: [],
    };
  }),
  on(EditCollectionActions.loadedCollectionItemsToAdd, (state, action) => {
    return {
      ...state,
      isLoadingCollectionsItemsToAdd: false,
      collectionItemsToAdd: action.collectionItemsToAdd,
    };
  }),
  on(EditCollectionActions.addCollectionItem, (state, action) => {
    return {
      ...state,
      isAddingCollectionItem: true,
    };
  }),
  on(EditCollectionActions.addedCollectionItem, (state, action) => {
    return {
      ...state,
      isAddingCollectionItem: false,
      collectionItems: [...state.collectionItems, action.collectionItem],
    };
  }),
  on(EditCollectionActions.removeCollectionItem, (state, action) => {
    return {
      ...state,
      isRemovingCollectionItem: true,
    };
  }),
  on(EditCollectionActions.removedCollectionItem, (state, action) => {
    return {
      ...state,
      isRemovingCollectionItem: false,
      collectionItems: state.collectionItems.filter(
        (ci) => ci.name !== action.collectionItemToRemove.name,
      ),
    };
  }),
  on(EditCollectionActions.editCollectionItems, (state, action) => {
    return {
      ...state,
      isUpdatingCollectionItems: true,
    };
  }),
  on(EditCollectionActions.editedCollectionItems, (state, action) => {
    return {
      ...state,
      isUpdatingCollectionItems: false,
      collectionItems: action.updatedCollectionItems,
    };
  }),
  on(EditCollectionActions.editCollectionItemsFailed, (state, _) => {
    return {
      ...state,
      isUpdatingCollectionItems: false,
    };
  }),
  on(EditCollectionActions.filteredCollectionItemsToAdd, (state, action) => {
    return {
      ...state,
      filteredCollectionItemsToAdd: action.filteredMusicPiecesToAdd,
    };
  }),
  on(EditCollectionActions.filteredCollectionItems, (state, action) => {
    return {
      ...state,
      filteredCollectionItems: action.filteredCollectionItems,
    };
  }),
  on(EditCollectionActions.loadLinkedCollections, (state, action) => {
    return {
      ...state,
      isLoadingLinkedCollections: true,
      linkedCollections: [],
    };
  }),
  on(EditCollectionActions.loadedLinkedCollections, (state, action) => {
    return {
      ...state,
      linkedCollections: action.linkedCollections,
      isLoadingLinkedCollections: false,
    };
  }),
  on(EditCollectionActions.loadedLinkedCollectionsFailed, (state, _) => {
    return {
      ...state,
      linkedCollections: [],
      isLoadingLinkedCollections: false,
    };
  }),
  on(EditCollectionActions.unshare, (state, action) => {
    return {
      ...state,
      linkedCollections: state.linkedCollections.filter(
        (lc) => lc.name != action.linkedCollection.name,
      ), // we are optimistic
    };
  }),
  on(EditCollectionActions.loadCollectionListing, (state, action) => ({
    ...state,
    isLoadingCollectionListing: true,
  })),
  on(EditCollectionActions.loadedCollectionListing, (state, action) => ({
    ...state,
    isLoadingCollectionListing: false,
    listing: action.listing,
  })),
  on(EditCollectionActions.saveCollectionListing, (state, action) => ({
    ...state,
    isSavingCollectionListing: true,
  })),
  on(EditCollectionActions.createdCollectionListing, (state, action) => ({
    ...state,
    isSavingCollectionListing: false,
    listing: action.listing,
  })),
  on(EditCollectionActions.updatedCollectionListing, (state, action) => ({
    ...state,
    isSavingCollectionListing: false,
    listing: action.listing,
  })),
  on(EditCollectionActions.deletedCollectionListing, (state, action) => ({
    ...state,
    isSavingCollectionListing: false,
    listing: undefined,
  })),
  on(EditCollectionActions.saveCollectionListingFailed, (state, action) => ({
    ...state,
    isSavingCollectionListing: false,
    listing: undefined,
  })),
);
