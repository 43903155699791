import { createReducer, createSelector, on } from "@ngrx/store";
import { Metronome } from "src/app/models/metronome/metronome";
import { AppState } from ".";
import { MetronomeActions, PlayerActions } from "../actions";

export interface MetronomeState {
  metronomes: Metronome[];
  selectedMetronome?: Metronome;
}

const initialState = {
  metronomes: [],
} as MetronomeState;

export const metronomeReducer = createReducer(
  initialState,
  on(PlayerActions.openPlayerAction, (state, action) => initialState),
  on(MetronomeActions.loadedMetronomes, (state, action) => {
    return {
      ...state,
      metronomes: action.metronomes,
    };
  }),
  on(MetronomeActions.selectedMetronome, (state, action) => {
    return {
      ...state,
      selectedMetronome: action.metronome,
    };
  }),
  on(MetronomeActions.createdMetronome, (state, action) => {
    // should we be optimistic or only show once we have actually added the metronome?
    return {
      ...state,
      metronomes: [...state.metronomes, action.metronome],
      selectedMetronome: action.metronome,
    };
  }),
  on(MetronomeActions.deleteMetronome, (state, action) => {
    // we are optimistic
    return {
      ...state,
      metronomes: [
        ...state.metronomes.filter((m) => m.name != action.metronome.name),
      ],
      selectedMetronome:
        action.metronome.name == state.selectedMetronome?.name
          ? undefined
          : state.selectedMetronome,
    };
  }),
  on(MetronomeActions.updateMetronome, (state, action) => {
    // we are optimistic
    return {
      ...state,
      metronomes: [
        ...state.metronomes.filter((m) => m.name != action.metronome.name),
        action.metronome,
      ],
      selectedMetronome:
        action.metronome.name == state.selectedMetronome?.name
          ? action.metronome
          : state.selectedMetronome,
    };
  })
);

export const selectMetronomes = createSelector(
  (state: AppState) => state.metronome.metronomes,
  (metronomes) => metronomes
);

export const selectSelectedMetronome = createSelector(
  (state: AppState) => state.metronome.selectedMetronome,
  (selectMetronome) => selectMetronome
);
