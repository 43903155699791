export interface License {
  name: string;
  title: string;
  musicPieces: number | "unlimited";
  practiceDurationThisMonthMs: number | "unlimited";
  storageBytes: number | "unlimited";
  canShare: boolean;
  recordingPartGroups: number | "unlimited";
  markers: number | "unlimited";
  musicScorePages: number | "unlimited";
  metronomes: number | "unlimited";
  canBecomeArtist: boolean;
  canListMusicPieces: boolean;
  canPitchShift: boolean;
  collections: number | "unlimited";
  collectionItems: number | "unlimited";
  practiceRoutines: number | "unlimited";
  canListCollections: boolean;
  canShareCollections: boolean;
  canSetCustomUrl: boolean;
  pricePerMonth: number | "free";
  pricePerYear: number | "free";
  stripePricePerMonthId?: string;
  stripePricePerYearId?: string;
  canSetupAudioTracks: boolean;
  canMuteAudioTracks: boolean;
}

export interface UserLicense {
  start: Date;
  end: Date;
  license: License;
}

export const emptyUserLicense = {
  start: new Date(9999, 1, 1),
  end: new Date(9999, 1, 1),
  license: {
    canShare: false,
    title: "",
    markers: 0,
    metronomes: 0,
    musicPieces: 0,
    musicScorePages: 0,
    practiceDurationThisMonthMs: 0,
    recordingPartGroups: 0,
    storageBytes: 0,
    canBecomeArtist: false,
    canListMusicPieces: false,
    canPitchShift: false,
    collections: 0,
    collectionItems: 0,
    canShareCollections: false,
    canListCollections: false,
    canSetCustomUrl: false,
    canMuteAudioTracks: false,
    canSetupAudioTracks: false,
    practiceRoutines: 0,
  },
} as UserLicense;
