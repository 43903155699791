import { createReducer, on } from "@ngrx/store";
import { PracticeRoutineDisplayItem } from "src/app/models/practice-routine/practice-routine-display-item";
import { PracticeRoutinesActions } from "../actions";

export interface PracticeRoutinesState {
  practiceRoutines: PracticeRoutineDisplayItem[];
  isLoading: boolean;
}

const initialState = {
  isLoading: false,
  practiceRoutines: [],
} as PracticeRoutinesState;

export const practiceRoutinesReducer = createReducer(
  initialState,
  on(PracticeRoutinesActions.load, (state, _) => ({
    ...state,
    isLoading: true,
  })),
  on(PracticeRoutinesActions.loaded, (state, action) => ({
    ...state,
    isLoading: false,
    practiceRoutines: action.practiceRoutines,
  })),
);
