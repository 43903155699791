import { createReducer, on } from "@ngrx/store";
import { StoredArtistProfile } from "src/app/models/artist/stored-artist-profile";
import { MusicPieceListing } from "src/app/models/music-piece/music-piece-listing";
import { Preview } from "src/app/models/music-piece/preview";
import { ArtistProfileActions } from "../actions";
import { ShowcaseCollectionListing } from "src/app/models/artist/showcase-collection-listing";
import { MusicPiece } from "src/app/models/music-piece/music-piece";
import { Recording } from "src/app/models/recording/recording";
import { Teaser } from "src/app/models/artist/teaster";

export interface ArtistProfileState {
  artistProfileForCurrentUser?: StoredArtistProfile;
  isLoadingArtistProfileForCurrentUser: boolean;
  artistProfile?: StoredArtistProfile;
  isLoadingArtistProfile: boolean;
  isLoadingListedMusicPieces: boolean;
  listedMusicPieces?: MusicPieceListing[];
  isLoadingListedCollections: boolean;
  listedCollections?: ShowcaseCollectionListing[];
  possibleTeasers: MusicPiece[];
  isLoadingPossibleTeasers: boolean;
  teaser?: Teaser;
  isLoadingTeaser: boolean;
  isUpdating: boolean;
  previews: Preview[];
  focusMusicPiece?: string;
}

const initialState = {
  isLoadingArtistProfileForCurrentUser: false,
  isLoadingArtistProfile: false,
  isLoadingListedMusicPieces: false,
  previews: [],
  isUpdating: false,
  isLoadingListedCollections: false,
  possibleTeasers: [],
  isLoadingPossibleTeasers: false,
  isLoadingTeaser: false,
} as ArtistProfileState;

export const artistProfileReducer = createReducer(
  initialState,
  on(ArtistProfileActions.loadArtistProfileForCurrentUser, (state, _) => {
    return {
      ...state,
      isLoadingArtistProfileForCurrentUser: true,
    };
  }),
  on(
    ArtistProfileActions.loadedArtistProfileForCurrentUser,
    (state, action) => {
      return {
        ...state,
        isLoadingArtistProfileForCurrentUser: false,
        artistProfileForCurrentUser: action.artistProfile,
      };
    },
  ),
  on(ArtistProfileActions.loadArtistProfile, (state, _) => {
    return {
      ...state,
      isLoadingArtistProfile: true,
      artistProfile: undefined,
      listedMusicPieces: undefined,
      isLoadingListedMusicPieces: true,
      isLoadingListedCollections: true,
    };
  }),
  on(ArtistProfileActions.loadArtistProfileByCustomUrl, (state, _) => ({
    ...state,
    isLoadingArtistProfile: true,
    artistProfile: undefined,
    listedMusicPieces: undefined,
    listedCollections: undefined,
    isLoadingListedMusicPieces: true,
    isLoadingListedCollections: true,
  })),
  on(ArtistProfileActions.loadedArtistProfile, (state, action) => {
    return {
      ...state,
      isLoadingArtistProfile: false,
      artistProfile: action.artistProfile,
    };
  }),
  on(ArtistProfileActions.loadedListedMusicPieces, (state, action) => {
    return {
      ...state,
      isLoadingListedMusicPieces: false,
      listedMusicPieces: action.musicPieces,
    };
  }),
  on(ArtistProfileActions.loadedListingPreviews, (state, action) => {
    return {
      ...state,
      previews: action.previews,
    };
  }),
  on(ArtistProfileActions.loadedListedCollections, (state, action) => ({
    ...state,
    isLoadingListedCollections: false,
    listedCollections: action.collections,
  })),
  on(ArtistProfileActions.updateProfile, (state, action) => {
    return {
      ...state,
      isUpdating: true,
    };
  }),
  on(ArtistProfileActions.updatedProfile, (state, action) => {
    return {
      ...state,
      artistProfile: action.artistProfile,
      artistProfileForCurrentUser: action.artistProfile,
      isUpdating: false,
    };
  }),
  on(ArtistProfileActions.updateProfileFailed, (state, action) => {
    return {
      ...state,
      isUpdating: false,
    };
  }),
  on(ArtistProfileActions.focusMusicPiece, (state, action) => {
    return {
      ...state,
      focusMusicPiece: action.musicPiece,
    };
  }),
  on(ArtistProfileActions.loadPossibleTeasers, (state, _) => ({
    ...state,
    isLoadingPossibleTeasers: true,
    possibleTeasers: [],
  })),
  on(ArtistProfileActions.loadedPossibleTeasers, (state, action) => ({
    ...state,
    isLoadingPossibleTeasers: false,
    possibleTeasers: action.teasers,
  })),
  on(ArtistProfileActions.loadTeaser, (state, _) => ({
    ...state,
    isLoadingTeaser: true,
    teaser: undefined,
  })),
  on(ArtistProfileActions.loadedTeaser, (state, action) => ({
    ...state,
    isLoadingTeaser: false,
    teaser: action.teaser,
  })),
);
