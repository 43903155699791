import { createReducer, on } from "@ngrx/store";

import { StoredPotentialUser } from "../../models/user/stored-potential-user";
import { RegistrationActions, UserActions } from "../actions";

export interface RegistrationState {
  wasSuccessful: boolean;
  isLoading: boolean;
  potentialUser?: StoredPotentialUser;
  wasVerifiedSuccessful: boolean;
}

const initialState = {
  isLoading: false,
  wasSuccessful: false,
  wasVerifiedSuccessful: false,
} as RegistrationState;

export const registrationReducer = createReducer(
  initialState,
  on(RegistrationActions.registerAction, (state) => {
    return { ...state, isLoading: true };
  }),
  on(RegistrationActions.finishGoogleSignUp, (state, action) => ({
    ...state,
    isLoading: true,
  })),
  on(RegistrationActions.registrationSuccessAction, (state, pu) => {
    return {
      ...state,
      isLoading: false,
      wasSuccessful: true,
    };
  }),
  on(RegistrationActions.registrationFailedAction, (state) => {
    return {
      ...state,
      isLoading: false,
      wasSuccessful: false,
    };
  }),
  on(RegistrationActions.verifyEmailAction, (state, data) => {
    return {
      ...state,
      isLoading: true,
    };
  }),
  on(RegistrationActions.verificationSuccessAction, (state) => {
    return {
      ...state,
      wasVerifiedSuccessful: true,
    };
  }),
  on(RegistrationActions.verificationFailedAction, (state) => {
    return {
      ...state,
      isLoading: false,
      wasVerifiedSuccessful: false,
    };
  }),
  on(UserActions.loggedIn, (state, _) => {
    return {
      ...state,
      isLoading: false,
    };
  }),
);
