import { createReducer, on } from "@ngrx/store";
import { VideoFeedbackActions } from "../actions";
import { VideoFeedback } from "src/app/models/video-feedback/video-feedback";
import { VideoFeedbackItem } from "src/app/models/video-feedback/video-feedback-item";

export interface VideoFeedbackState {
  videoFeedback?: VideoFeedback;
  isLoadingVideoFeedback: boolean;
  videoFeedbackItems: VideoFeedbackItem[];
  isLoadingVideoFeedbackItems: boolean;
  isCreatingVideoFeedbackItem: boolean;
  isMarkingAsFinished: boolean;
  isUpdatingTheme: boolean;
}

const initialState = {
  isLoadingVideoFeedback: false,
  isLoadingVideoFeedbackItems: false,
  isCreatingVideoFeedbackItem: false,
  isMarkingAsFinished: false,
  isUpdatingTheme: false,
} as VideoFeedbackState;

export const videoFeedbackReducer = createReducer(
  initialState,
  on(VideoFeedbackActions.load, (state, _) => ({
    ...state,
    isLoadingVideoFeedback: true,
    videoFeedback: undefined,
  })),
  on(VideoFeedbackActions.loaded, (state, action) => ({
    ...state,
    isLoadingVideoFeedback: false,
    videoFeedback: action.videoFeedback,
  })),
  on(VideoFeedbackActions.loadItems, (state, _) => ({
    ...state,
    videoFeedbackItems: [],
    isLoadingVideoFeedbackItems: true,
  })),
  on(VideoFeedbackActions.loadedItems, (state, action) => ({
    ...state,
    isLoadingVideoFeedbackItems: false,
    videoFeedbackItems: action.videoFeedbackItems,
  })),
  on(VideoFeedbackActions.createVideoFeedbackItem, (state, _) => ({
    ...state,
    isCreatingVideoFeedbackItem: true,
  })),
  on(VideoFeedbackActions.createdVideoFeedbackItem, (state, action) => ({
    ...state,
    isCreatingVideoFeedbackItem: false,
    videoFeedbackItems: [...state.videoFeedbackItems, action.videoFeedbackItem],
  })),
  on(VideoFeedbackActions.createVideoFeedbackItemFailed, (state, _) => ({
    ...state,
    isCreatingVideoFeedbackItem: false,
  })),
  on(VideoFeedbackActions.markedAsRead, (state, action) => ({
    ...state,
    videoFeedbackItems: action.videoFeedbackItems,
  })),
  on(VideoFeedbackActions.markAsFinished, (state, _) => ({
    ...state,
    isMarkingAsFinished: true,
  })),
  on(VideoFeedbackActions.markedAsFinished, (state, action) => ({
    ...state,
    isMarkingAsFinished: false,
    videoFeedback: action.videoFeedback,
  })),
  on(VideoFeedbackActions.updateTheme, (state, _) => ({
    ...state,
    isUpdatingTheme: true,
  })),
  on(VideoFeedbackActions.updatedTheme, (state, action) => ({
    ...state,
    isUpdatingTheme: false,
    videoFeedback: action.videoFeedback,
  })),
);
