import { createReducer, on } from "@ngrx/store";
import { StoredCollection } from "src/app/models/collection/stored-collection";
import { StoredLinkedCollection } from "src/app/models/collection/stored-linked-collection";
import { EditCollectionActions, ReadonlyCollectionActions } from "../actions";
import { StoredLinkedCollectionItem } from "src/app/models/collection/stored-linked-collection-item";
import { LinkedCollectionItemToDisplay } from "src/app/models/collection/collection-item-to-display";

export interface ReadonlyCollectionState {
  isLoadingCollection: boolean;
  collection?: StoredCollection;
  linkedCollection?: StoredLinkedCollection;
  collectionItems: StoredLinkedCollectionItem[];
  isLoadingCollectionItems: boolean;
  filteredCollectionItems: LinkedCollectionItemToDisplay[];
  isUpdatingLinkedCollection: boolean;
  isUpdatingCollection: boolean;
  isUpdatingLinkedCollectionItems: boolean;
}

const initialState = {
  isLoadingCollection: false,
  collectionItems: [],
  filteredCollectionItems: [],
  isLoadingCollectionItems: false,
  isUpdatingLinkedCollection: false,
  isUpdatingCollection: false,
  isUpdatingLinkedCollectionItems: false,
} as ReadonlyCollectionState;

export const readonlyCollectionReducer = createReducer(
  initialState,
  on(ReadonlyCollectionActions.open, (state, action) => {
    return {
      ...state,
      isLoadingCollection: true,
      collection: undefined,
      linkedCollection: undefined,
    };
  }),
  on(ReadonlyCollectionActions.loadedReadonlyCollection, (state, action) => {
    return {
      ...state,
      isLoadingCollection: false,
      collection: action.collection,
      linkedCollection: action.linkedCollection,
    };
  }),
  on(ReadonlyCollectionActions.loadCollectionItems, (state, _) => {
    return {
      ...state,
      isLoadingCollectionItems: true,
      collectionItems: [],
    };
  }),
  on(ReadonlyCollectionActions.loadedCollectionItems, (state, action) => {
    return {
      ...state,
      isLoadingCollectionItems: false,
      collectionItems: action.linkedCollectionItems,
    };
  }),
  on(ReadonlyCollectionActions.filteredCollectionItems, (state, action) => {
    return {
      ...state,
      filteredCollectionItems: action.filteredCollectionItems,
    };
  }),
  on(
    ReadonlyCollectionActions.deleteCollection,
    (state, action) => initialState,
  ),
  on(
    ReadonlyCollectionActions.deleteLinkedCollection,
    (state, action) => initialState,
  ),
  on(ReadonlyCollectionActions.updateLinkedCollection, (state, action) => ({
    ...state,
    isUpdatingLinkedCollection: true,
  })),
  on(ReadonlyCollectionActions.updatedLinkedCollection, (state, action) => ({
    ...state,
    linkedCollection: action.collection,
    isUpdatingLinkedCollection: false,
  })),
  on(ReadonlyCollectionActions.updateCollection, (state, action) => ({
    ...state,
    isUpdatingCollection: true,
  })),
  on(ReadonlyCollectionActions.updatedCollection, (state, action) => ({
    ...state,
    isUpdatingCollection: false,
    collection: action.collection,
  })),
  on(ReadonlyCollectionActions.editLinkedCollectionItems, (state, action) => ({
    ...state,
    isUpdatingLinkedCollectionItems: true,
  })),
  on(
    ReadonlyCollectionActions.editedLinkedCollectionItems,
    (state, action) => ({
      ...state,
      isUpdatingLinkedCollectionItems: false,
      collectionItems: action.updatedCollectionItems,
    }),
  ),
  on(
    ReadonlyCollectionActions.editLinkedCollectionItemsFailed,
    (state, action) => ({
      ...state,
      isUpdatingLinkedCollectionItems: false,
    }),
  ),
);
