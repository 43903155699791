import { createReducer, createSelector, on } from "@ngrx/store";

import { Marker } from "src/app/models/marker/marker";
import { EditGuard } from "src/app/models/player/edit-guard";
import { AppState } from ".";
import {
  MarkerActions,
  PlayerActions,
  RecordingPartGroupActions,
} from "../actions";
import { markerInEditModeChange } from "../actions/marker.actions";

export interface MarkerState {
  markerInEditMode?: Marker;
  editGuard?: EditGuard;
  markers: Marker[];
}

const initialState = {
  markers: [],
} as MarkerState;

export const markerReducer = createReducer(
  initialState,
  on(PlayerActions.openPlayerAction, (state, action) => initialState),
  on(
    RecordingPartGroupActions.deletedRecordingPartGroup,
    (state, action) => initialState
  ),
  on(MarkerActions.addedMarker, (state, action) => {
    return {
      ...state,
      markers: [...state.markers, action.marker],
    };
  }),
  on(MarkerActions.markerInEditModeChange, (state, action) => {
    return {
      ...state,
      markerInEditMode: action.marker,
      editGuard: action.editGuard,
    };
  }),
  on(MarkerActions.markersLoaded, (state, action) => {
    return {
      ...state,
      markers: action.markers,
    };
  }),
  on(MarkerActions.deleteMarker, (state, action) => {
    // optimistic: deleteMarker rather than deletedMarker
    return {
      ...state,
      markers: state.markers.filter((m) => m.name != action.marker.name),
      markerInEditMode: undefined,
      editGuard: undefined,
    };
  }),
  on(MarkerActions.moveMarker, (state, action) => {
    if (state.markerInEditMode && state.editGuard) {
      const frameTimeMs = 1000 / 30; // for now we assume 30fps
      const newMs = Math.round(
        action.direction == ">"
          ? state.markerInEditMode.timestampMs + frameTimeMs
          : state.markerInEditMode.timestampMs - frameTimeMs
      );
      if (
        newMs > state.editGuard.leftGuard.timestampMs &&
        newMs < state.editGuard.rightGuard.timestampMs
      ) {
        const newMarker = { ...state.markerInEditMode, timestampMs: newMs };
        return {
          ...state,
          markers: [
            ...state.markers.filter((m) => m.name != newMarker.name),
            newMarker,
          ],
          markerInEditMode: newMarker,
        };
      }
    }
    return {
      ...state,
    };
  })
);

export const selectMarkers = createSelector(
  (state: AppState) => state.marker.markers,
  (markers) => markers
);

export const selectMarkerInEditMode = createSelector(
  (state: AppState) => state.marker.markerInEditMode,
  (state: AppState) => state.marker.editGuard,
  (marker, editGuard) => {
    return {
      marker: marker,
      editGuard: editGuard,
    };
  }
);

export const selectMarkerInEditModeWithSelectedRecordingPartGroup =
  createSelector(
    (state: AppState) => state.marker.markerInEditMode,
    (state: AppState) => state.recordingPartGroups.selectedRecordingPartGroup,
    (marker, rpg) => {
      return {
        marker: marker,
        selectedRecordingPartGroup: rpg,
      };
    }
  );
