import { createReducer, on } from "@ngrx/store";
import { PracticeRoutineDisplayItem } from "src/app/models/practice-routine/practice-routine-display-item";
import {
  PlayerActions,
  PracticeRoutineActions,
  PracticeRoutinesActions,
} from "../actions";
import { PracticeConfirmation } from "src/app/models/practice-routine/practice-confirmation";
import { StoredCollectionItem } from "src/app/models/collection/stored-collection-item";
import { StoredLinkedCollectionItem } from "src/app/models/collection/stored-linked-collection-item";
import { CollectionType } from "../actions/practice-routine.actions";
import { practiceRoutine } from "../selectors/practice-routine.selector";

export interface PracticeRoutineState {
  practiceRoutine?: PracticeRoutineDisplayItem;
  collectionType?: CollectionType;
  isLoading: boolean;
  practiceConfirmations: PracticeConfirmation[];
  isLoadingPracticeConfirmations: boolean;
  isLoadingCollectionItems: boolean;
  collectionItems: StoredCollectionItem[];
  linkedCollectionItems: StoredLinkedCollectionItem[];
  isMarkingAsPracticed: boolean;
  isLoadingPlayerCollectionItem: boolean;
  playerCollectionItem?: string;
  playerCollectionType?: CollectionType;
}

const initialState = {
  isLoading: false,
  isLoadingPracticeConfirmations: false,
  practiceConfirmations: [],
  collectionItems: [],
  isLoadingCollectionItems: false,
  linkedCollectionItems: [],
  isMarkingAsPracticed: false,
  playerCollectionItem: undefined,
  isLoadingPlayerCollectionItem: false,
} as PracticeRoutineState;

export const practiceRoutineReducer = createReducer(
  initialState,
  on(PracticeRoutineActions.load, (state, action) => ({
    ...state,
    isLoading: true,
    practiceConfirmations: [],
    practiceRoutine: undefined,
    collectionType: action.collectionType,
  })),
  on(PracticeRoutineActions.loaded, (state, action) => ({
    ...state,
    isLoading: false,
    practiceRoutine: action.practiceRoutine,
  })),
  on(PracticeRoutineActions.loadPracticeConfirmations, (state, _) => ({
    ...state,
    isLoadingPracticeConfirmations: true,
  })),
  on(PracticeRoutineActions.loadedPracticeConfirmations, (state, action) => ({
    ...state,
    isLoadingPracticeConfirmations: false,
    practiceConfirmations: action.practiceConfirmations,
  })),
  on(PracticeRoutineActions.loadCollectionItems, (state, _) => ({
    ...state,
    isLoadingCollectionItems: true,
  })),
  on(PracticeRoutineActions.loadedCollectionItems, (state, action) => ({
    ...state,
    isLoadingCollectionItems: false,
    collectionItems: action.collectionItems,
    linkedCollectionItems: action.linkedCollectionItems,
  })),
  on(PracticeRoutineActions.markAsPracticed, (state, _) => ({
    ...state,
    isMarkingAsPracticed: true,
  })),
  on(PracticeRoutineActions.markedAsPracticed, (state, action) => ({
    ...state,
    isMarkingAsPracticed: false,
    practiceConfirmations: [
      ...state.practiceConfirmations,
      action.confirmation,
    ],
  })),
  on(PracticeRoutineActions.loadPlayerCollectionItem, (state, action) => ({
    ...state,
    isLoadingPlayerCollectionItem: true,
    playerCollectionItem: undefined,
    playerCollectionType: undefined,
  })),
  on(PracticeRoutineActions.loadedPlayerCollectionItem, (state, action) => ({
    ...state,
    isLoadingPlayerCollectionItem: false,
    playerCollectionItem: action.collectionItem,
    playerCollectionType: action.collectionType,
  })),
  on(PlayerActions.openPlayerAction, (state, action) => ({
    ...state,
    playerCollectionItem: undefined,
    playerCollectionType: undefined,
    isLoadingPlayerCollectionItem: false,
  })),
);
