import { createReducer, on } from "@ngrx/store";
import { MusicPiecePurchase } from "src/app/models/music-piece/music-piece-purchase";
import { PurchaseSettings } from "src/app/models/purchase/purchase-settings";
import { PurchaseActions } from "../actions";
import { CollectionPurchase } from "src/app/models/collection/collection-purchase";
import { PreparedPurchaseItem } from "src/app/models/purchase/purchased-item";
import { VideoFeedbackPurchase } from "src/app/models/video-feedback/video-feedback-purchase";

export interface PurchaseState {
  purchasedMusicPieces: MusicPiecePurchase[];
  purchasedCollections: CollectionPurchase[];
  purchasedVideoFeedbacks: VideoFeedbackPurchase[];

  isLoadingPurchasedMusicPieces: boolean;
  isLoadingCollectionPurchases: boolean;
  isLoadingVideoFeedbackPurchases: boolean;
  purchaseSettings?: PurchaseSettings;
  currentPurchase?: PreparedPurchaseItem;
  detailPurchaseStatus?: PreparedPurchaseItem;
  isLoadingDetailPurchaseStatus: boolean;
}

const initialState = {
  isLoadingPurchasedMusicPieces: false,
  purchasedMusicPieces: [],
  purchasedCollections: [],
  purchasedVideoFeedbacks: [],
  isLoadingDetailPurchaseStatus: false,
  isLoadingCollectionPurchases: false,
  isLoadingVideoFeedbackPurchases: false,
} as PurchaseState;

export const purchaseReducer = createReducer(
  initialState,
  on(PurchaseActions.loadPurchasedMusicPieces, (state, action) => {
    return {
      ...state,
      isLoadingPurchasedMusicPieces: true,
      purchasedMusicPieces: [],
    };
  }),
  on(PurchaseActions.loadedPurchasedMusicPieces, (state, action) => {
    return {
      ...state,
      isLoadingPurchasedMusicPieces: false,
      purchasedMusicPieces: action.purchasedMusicPieces,
    };
  }),
  on(PurchaseActions.loadPurchasedCollections, (state, action) => ({
    ...state,
    isLoadingCollectionPurchases: true,
    purchasedCollections: [],
  })),
  on(PurchaseActions.loadedPurchasedCollections, (state, action) => ({
    ...state,
    isLoadingCollectionPurchases: false,
    purchasedCollections: action.purchasedCollections,
  })),
  on(PurchaseActions.loadPurchaseSettings, (state, action) => {
    return {
      ...state,
      purchaseSettings: undefined,
    };
  }),
  on(PurchaseActions.loadPurchasedVideoFeedbacks, (state, action) => ({
    ...state,
    isLoadingVideoFeedbackPurchases: true,
  })),
  on(PurchaseActions.loadedPurchasedVideoFeedbacks, (state, action) => ({
    ...state,
    isLoadingVideoFeedbackPurchases: false,
    purchasedVideoFeedbacks: action.videoFeedbackPurchases,
  })),
  on(PurchaseActions.loadedPurchaseSettings, (state, action) => {
    return {
      ...state,
      purchaseSettings: action.settings,
    };
  }),
  on(PurchaseActions.purchase, (state, action) => {
    return {
      ...state,
      currentPurchase: undefined,
    };
  }),
  on(PurchaseActions.purchasePrepared, (state, action) => {
    var purchasedVideoFeedbacks = state.purchasedVideoFeedbacks;
    if (action.item.type == "video-feedback") {
      purchasedVideoFeedbacks = [
        ...purchasedVideoFeedbacks,
        action.item.item as VideoFeedbackPurchase,
      ];
    }

    var purchasedCollections = state.purchasedCollections;
    if (action.item.type == "collection") {
      purchasedCollections = [
        ...purchasedCollections,
        action.item.item as CollectionPurchase,
      ];
    }

    var purchasedMusicPieces = state.purchasedMusicPieces;
    if (action.item.type == "music-piece") {
      purchasedMusicPieces = [
        ...purchasedMusicPieces,
        action.item.item as MusicPiecePurchase,
      ];
    }

    return {
      ...state,
      currentPurchase: action.item,
      purchasedMusicPieces: purchasedMusicPieces,
      purchasedVideoFeedbacks: purchasedVideoFeedbacks,
      purchasedCollections: purchasedCollections,
    };
  }),
  on(PurchaseActions.purchaseSucceeded, (state, action) => {
    return {
      ...state,
      currentPurchase: undefined,
    };
  }),
  on(PurchaseActions.purchaseFailed, (state, action) => {
    return {
      ...state,
      currentPurchase: undefined,
    };
  }),
  on(PurchaseActions.loadPurchaseDetails, (state, action) => {
    return {
      ...state,
      isLoadingDetailPurchaseStatus: true,
      currentPurchase: undefined,
      detailPurchaseStatus: undefined,
    };
  }),
  on(PurchaseActions.loadedPurchaseDetails, (state, action) => {
    return {
      ...state,
      isLoadingDetailPurchaseStatus: false,
      detailPurchaseStatus: action.purchase,
    };
  }),
);
