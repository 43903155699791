import { createReducer, createSelector, on } from "@ngrx/store";
import { AppState } from ".";
import { PlayerActions, RecordingPartGroupActions } from "../actions";
import { LinkedMusicPiece } from "../../models/linked-music-piece/linked-music-piece";
import { MusicPiece } from "../../models/music-piece/music-piece";
import { MusicScore } from "../../models/music-score/music-score";
import { PageBreak } from "../../models/page-break/page-break";
import { Recording } from "../../models/recording/recording";
import { DisplayedPage } from "src/app/components/music-score/music-score/music-score.component";
import { MusicScoreOverlay } from "src/app/models/music-score/music-score-overlay";
import { Brush } from "src/app/components/player/recording-part-painter/recording-part-painter.component";
import { selectSelectedRecordingPartGroup } from "./recording-part-group.reducer";

export interface PlayerState {
  musicPiece?: MusicPiece;
  linkedMusicPiece?: LinkedMusicPiece;
  isLinkedMusicPiece: boolean;
  musicScore?: MusicScore;
  recording?: Recording;
  pageBreaks: PageBreak[];
  hasLoadedRecording: boolean;
  hasRecordingLoadingError: boolean;
  hasLoadedMusicScore: boolean;
  hasLoadedMusicPiece: boolean;
  hasAutoPageFlip: boolean;
  showOverlay: boolean;
  displayedPage?: DisplayedPage;
  overlay?: MusicScoreOverlay;
  brush?: Brush;
  isInDrawingMode: boolean;
  pitchShift: number;
  mutedTracks: number[];
}

const initialState = {
  isLinkedMusicPiece: false,
  hasRecordingLoadingError: false,
  hasLoadedMusicScore: false,
  hasLoadedRecording: false,
  hasLoadedMusicPiece: false,
  pageBreaks: [],
  hasAutoPageFlip: true,
  recordingPartGroups: [],
  recordingParts: [],
  showOverlay: true,
  isInDrawingMode: false,
  pitchShift: 0,
  mutedTracks: [],
} as PlayerState;

export const playerReducer = createReducer(
  initialState,
  on(PlayerActions.openPlayerAction, (state, action) => {
    return {
      ...initialState,
      isLinkedMusicPiece: action.isLinkedMusicPiece,
    };
  }),
  on(RecordingPartGroupActions.deletedRecordingPartGroup, (state, action) => {
    return {
      ...state,
      overlay: undefined,
      showOverlay: false,
    };
  }),
  on(PlayerActions.musicPieceLoaded, (state, p) => {
    return {
      ...state,
      musicPiece: p.musicPiece,
      linkedMusicPiece: p.linkedMusicPiece,
      isLinkedMusicPiece: p.linkedMusicPiece ? true : false,
      hasLoadedMusicPiece: true,
    };
  }),
  on(PlayerActions.recordingLoaded, (state, action) => {
    return {
      ...state,
      hasRecordingLoadingError: false,
      hasLoadedRecording: true,
      recording: action.recording,
    };
  }),
  on(PlayerActions.recordingLoadingFailed, (state, _) => {
    return {
      ...state,
      hasRecordingLoadingError: true,
      hasLoadedRecording: false,
      recording: undefined,
    };
  }),
  on(PlayerActions.pageBreaksLoaded, (state, action) => {
    return {
      ...state,
      pageBreaks: action.pageBreaks,
    };
  }),

  on(PlayerActions.musicScoreLoaded, (state, action) => {
    return {
      ...state,
      musicScore: action.musicScore,
    };
  }),
  on(PlayerActions.pageBreakAdded, (state, action) => {
    return {
      ...state,
      pageBreaks: [...state.pageBreaks, action.pageBreak],
    };
  }),
  on(PlayerActions.pageBreakDeleted, (state, action) => {
    return {
      ...state,
      pageBreaks: state.pageBreaks.filter(
        (pb) => pb.name != action.pageBreak.name,
      ),
    };
  }),
  on(PlayerActions.setAutoPageFlip, (state, action) => {
    return {
      ...state,
      hasAutoPageFlip: action.hasAutoPageFlip,
    };
  }),

  on(PlayerActions.showOverlay, (state, action) => {
    return {
      ...state,
      showOverlay: action.showOverlay,
    };
  }),
  on(PlayerActions.displayedPageChanged, (state, action) => {
    const shouldResetOverlay =
      action.previousPageNumber != (action.displayedPage?.pageNumber || 0);

    return {
      ...state,
      displayedPage: action.displayedPage,
      overlay: shouldResetOverlay ? undefined : state.overlay,
    };
  }),
  on(PlayerActions.overlayLoaded, (state, action) => {
    return {
      ...state,
      overlay: action.overlay,
    };
  }),
  on(PlayerActions.brushChanged, (state, action) => {
    return {
      ...state,
      brush: action.brush,
    };
  }),
  on(PlayerActions.isInDrawModeChanged, (state, action) => {
    return {
      ...state,
      isInDrawingMode: action.isInDrawMode,
    };
  }),
  on(PlayerActions.savedImage, (state, action) => {
    return {
      ...state,
      overlay: action.image,
    };
  }),
  on(PlayerActions.changePitch, (state, action) => {
    const pitch = Math.min(Math.max(action.pitch, -1200), 1200);
    console.log(pitch);
    return {
      ...state,
      pitchShift: pitch,
    };
  }),
  on(PlayerActions.muteTracks, (state, action) => ({
    ...state,
    mutedTracks: action.mutedTracks,
  })),
);

export const selectPlayerFeature = (state: AppState) => state.player;

// **IMPORTANT**: we have to combine the selectors this way, if we don't the selectors would be called for every "selector" in the argument
// which would mean that the selector is triggered for every store change.
export const selectRecordingWithError = createSelector(
  (state: AppState) => state.player.recording,
  (state: AppState) => state.player.hasRecordingLoadingError,
  (recording, hasRecordingLoadingError) => {
    return {
      recording: recording,
      hasRecordingLoadingError: hasRecordingLoadingError,
    };
  },
);

export const selectMusicPiece = createSelector(
  (state: AppState) => state.player.musicPiece,
  (musicPiece) => musicPiece,
);

export const selectPageBreaks = createSelector(
  (state: AppState) => state.player.pageBreaks,
  (pageBreaks) => pageBreaks,
);

export const selectMusicScore = createSelector(
  (state: AppState) => state.player.musicScore,
  (musicScore) => musicScore,
);

export const selectHasAutoPageFlip = createSelector(
  (state: AppState) => state.player.hasAutoPageFlip,
  (hasAutoPageFlip) => hasAutoPageFlip,
);

export const selectShowOverlay = createSelector(
  (state: AppState) => state.player.showOverlay,
  (showOverlay) => showOverlay,
);

export const selectDisplayedPage = createSelector(
  (state: AppState) => state.player.displayedPage,
  (displayedPage) => displayedPage,
);

export const selectOverlay = createSelector(
  (state: AppState) => state.player.overlay,
  (overlay) => overlay,
);

export const selectSelectedRecordingPartGroupWithDisplayPage = createSelector(
  selectSelectedRecordingPartGroup,
  selectDisplayedPage,
  (state: AppState) => state.player.linkedMusicPiece,
  (rpg, dp, linkedMusicPiece) => {
    return {
      recordingPartGroup: rpg,
      displayedPage: dp,
      linkedMusicPiece: linkedMusicPiece,
    };
  },
);

export const selectBrush = createSelector(
  (state: AppState) => state.player.brush,
  (brush) => brush,
);

export const selectIsInDrawingMode = createSelector(
  (state: AppState) => state.player.isInDrawingMode,
  (isInDrawingMode) => isInDrawingMode,
);

export const selectDisplayedPageWithOverlayAndRecordingPartGroup =
  createSelector(
    selectDisplayedPage,
    selectOverlay,
    selectSelectedRecordingPartGroup,
    (dp, overlay, selectedGroup) => {
      return {
        displayedPage: dp,
        overlay: overlay,
        selectedGroup: selectedGroup,
      };
    },
  );

export const selectIsReadonly = createSelector(
  (state: AppState) => state.player.isLinkedMusicPiece,
  (isLinkedMusicPiece) => isLinkedMusicPiece,
);

export const selectPitchShift = createSelector(
  (state: AppState) => state.player.pitchShift,
  (pitch) => pitch,
);

export const selectMutedTracks = createSelector(
  (state: AppState) => state.player,
  (p) => p.mutedTracks,
);
