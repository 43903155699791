import { createReducer, on } from "@ngrx/store";
import {
  elapsedPlaybackTimeAction,
  loggedElapsedPlaybackTimeSuccessfullyAction,
  loggedPracticedTimeSuccessfullyAction,
  startPracticeTimeAction,
  stopPracticeTimeAction,
} from "../actions/used-resources.actions";

export interface UsedResourcesState {
  isPracticing: boolean;
  lastIsPracticingStart: number;
  elapsedPlaybackTime: number;
}

const initialState = {
  isPracticing: false,
  lastIsPracticingStart: 0,
  elapsedPlaybackTime: 0,
} as UsedResourcesState;

export const usedResourcesReducer = createReducer(
  initialState,
  on(startPracticeTimeAction, (state) => {
    return {
      ...state,
      isPracticing: true,
      lastIsPracticingStart: new Date().valueOf(),
      elapsedPlaybackTime: 0,
    };
  }),
  on(stopPracticeTimeAction, (state) => {
    return { ...state, isPracticing: false };
  }),
  on(loggedPracticedTimeSuccessfullyAction, (state, data) => {
    return {
      ...state,
      lastIsPracticingStart: data.nextStartTime,
    };
  }),
  on(elapsedPlaybackTimeAction, (state, data) => {
    return {
      ...state,
      elapsedPlaybackTime: state.elapsedPlaybackTime + data.playbackTime,
    };
  }),
  on(loggedElapsedPlaybackTimeSuccessfullyAction, (state, data) => {
    return {
      ...state,
      elapsedPlaybackTime: state.elapsedPlaybackTime - data.loggedPlaybackTime,
    };
  }),
);
