import { createReducer, on, createSelector } from "@ngrx/store";
import { StoredUser } from "src/app/models/user/stored-user";
import { UserData } from "src/app/models/user/user-data";
import { AppState } from ".";
import { LicenseActions, UserActions } from "../actions";
import { StripeCustomer } from "src/app/models/user/stripe-customer";

export interface UserState {
  loginData?: UserData;
  storedUser?: StoredUser;
  stripeCustomer?: StripeCustomer;
  canUpgrade: boolean;
}
const initialState = {
  canUpgrade: false,
} as UserState;

export const userReducer = createReducer(
  initialState,
  on(UserActions.login, () => initialState),
  on(UserActions.logout, () => initialState),
  on(UserActions.loggedIn, (state, action) => {
    return {
      ...state,
      loginData: action.userData,
    };
  }),
  on(UserActions.loadUserDetails, (state) => {
    return {
      ...state,
      storedUser: undefined,
    };
  }),
  on(UserActions.loadedUserDetails, (state, action) => {
    return {
      ...state,
      storedUser: action.userDetails,
    };
  }),
  on(UserActions.switchLanguage, (state, action) => {
    // we are optimistic
    const user = state.storedUser
      ? { ...state.storedUser, language: action.language }
      : undefined;
    const loginData = state.loginData
      ? { ...state.loginData, language: action.language }
      : undefined;
    return {
      ...state,
      storedUser: user,
      loginData: loginData,
    };
  }),
  on(UserActions.updatedPassword, (state, action) => {
    const newUserData: UserData | undefined = state.loginData
      ? { ...state.loginData, apiSecret: action.apiSecret }
      : undefined;

    return {
      ...state,
      loginData: newUserData,
    };
  }),
  on(UserActions.loadStripeCustomer, (state, action) => {
    return {
      ...state,
      stripeCustomer: undefined,
    };
  }),
  on(UserActions.loadedStripeCustomer, (state, action) => {
    return {
      ...state,
      stripeCustomer: action.customer,
    };
  }),
  on(LicenseActions.loadedFallbackAndUpgrade, (state, action) => {
    return {
      ...state,
      canUpgrade: action.upgrade !== undefined,
    };
  }),
);

export const userFeature = (state: AppState) => state.user;

const loginIsNotExpired = (us: UserState) =>
  (us.loginData && us.loginData.validUntil.getTime() > Date.now()) || false;

export const isLoggedIn = createSelector(userFeature, (us) =>
  loginIsNotExpired(us),
);

export const currentUser = createSelector(userFeature, (us) => {
  if (loginIsNotExpired(us)) {
    return us.loginData;
  }
  return;
});

export const currentUserDetails = createSelector(userFeature, (us) => {
  if (loginIsNotExpired(us)) {
    return us.storedUser;
  }
  return;
});

export const stripeCustomer = createSelector(userFeature, (us) => {
  return {
    stripeCustomer: us.stripeCustomer,
  };
});

export const canUpgrade = createSelector(userFeature, (us) => {
  return {
    canUpgrade: us.canUpgrade,
  };
});
