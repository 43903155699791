import { createReducer, on } from "@ngrx/store";
import { PracticeRoutinesActions, VideoFeedbacksActions } from "../actions";
import { VideoFeedback } from "src/app/models/video-feedback/video-feedback";

export interface VideoFeedbacksState {
  videoFeedbacks: VideoFeedback[];
  isLoading: boolean;
  filteredVideoFeedbacks: VideoFeedback[];
}

const initialState = {
  isLoading: false,
  videoFeedbacks: [],
  filteredVideoFeedbacks: [],
} as VideoFeedbacksState;

export const videoFeedbacksReducer = createReducer(
  initialState,
  on(VideoFeedbacksActions.load, (state, _) => ({
    ...state,
    isLoading: true,
  })),
  on(VideoFeedbacksActions.loaded, (state, action) => ({
    ...state,
    isLoading: false,
    videoFeedbacks: action.videoFeedbacks,
    filteredVideoFeedbacks: action.videoFeedbacks,
  })),
  on(VideoFeedbacksActions.filtered, (state, action) => ({
    ...state,
    filteredVideoFeedbacks: action.filteredVideoFeedbacks,
  })),
);
