import { createReducer, createSelector, on } from "@ngrx/store";
import { LinkedMusicPiece } from "src/app/models/linked-music-piece/linked-music-piece";
import { MusicPiece } from "src/app/models/music-piece/music-piece";
import { MusicPieceListing } from "src/app/models/music-piece/music-piece-listing";
import { SharedMusicPieceReceiver } from "src/app/models/music-piece/shared-music-piece-receiver";
import { MusicScore } from "src/app/models/music-score/music-score";
import { Recording } from "src/app/models/recording/recording";
import { AppState } from ".";
import { EditMusicPieceActions } from "../actions";

export interface EditMusicPieceState {
  isNew: boolean;
  isLinkedMusicPiece: boolean;
  name: string;
  isLoading: boolean;
  musicPiece?: MusicPiece;
  linkedMusicPiece?: LinkedMusicPiece;
  musicScore?: MusicScore;
  isLoadingMusicScore: boolean;
  recording?: Recording;
  isLoadingRecording: boolean;
  sharedMusicPieceReceivers: SharedMusicPieceReceiver[];
  listing?: MusicPieceListing;
  isUploadingMusicScore: boolean;
  isUploadingRecording: boolean;
  isUpdatingRecording: boolean;
}

const initialState = {
  isNew: false,
  isLinkedMusicPiece: false,
  name: "",
  isLoading: true,
  isLoadingMusicScore: false,
  isLoadingRecording: false,
  sharedMusicPieceReceivers: [],
  isUploadingMusicScore: false,
  isUploadingRecording: false,
  isUpdatingRecording: false,
} as EditMusicPieceState;

export const editMusicPieceReducer = createReducer(
  initialState,
  on(EditMusicPieceActions.openMusicPieceForEdit, (state, action) => {
    return {
      ...state,
      isNew: action.isNew,
      isLinkedMusicPiece: action.isLinkedMusicPiece,
      name: action.name,
      isLoading: action.isNew ? false : true,
      musicPiece: undefined,
      linkedMusicPiece: undefined,
      isLoadingMusicScore: true,
      isLoadingRecording: true,
      listing: undefined,
      isUploadingMusicScore: false,
      isUploadingRecording: false,
    };
  }),
  on(EditMusicPieceActions.loaded, (state, action) => {
    return {
      ...state,
      isLoading: false,
      linkedMusicPiece: action.linkedMusicPieceWithMusicPiece?.linkedMusicPiece,
      musicPiece:
        action.musicPiece ?? action.linkedMusicPieceWithMusicPiece?.musicPiece,
    };
  }),
  on(EditMusicPieceActions.saved, (state, action) => {
    return {
      ...state,
      musicPiece: action.musicPiece,
      isNew: false,
      name: action.musicPiece.name,
    };
  }),
  on(EditMusicPieceActions.loadedMusicScore, (state, action) => {
    return {
      ...state,
      isLoadingMusicScore: false,
      musicScore: action.musicScore,
    };
  }),
  on(EditMusicPieceActions.loadedRecording, (state, action) => {
    return {
      ...state,
      isLoadingRecording: false,
      isUpdatingRecording: false,
      recording: action.recording,
    };
  }),
  on(EditMusicPieceActions.deleteMusicScore, (state, action) => {
    // we are optimistic
    return {
      ...state,
      musicScore: undefined,
      isLoadingMusicScore: true,
    };
  }),
  on(EditMusicPieceActions.deletedMusicScore, (state, action) => {
    return {
      ...state,
      isLoadingMusicScore: false,
    };
  }),
  on(EditMusicPieceActions.deleteRecording, (state, action) => {
    // we are optimistic
    return {
      ...state,
      recording: undefined,
      isLoadingRecording: true,
    };
  }),
  on(EditMusicPieceActions.deletedRecording, (state, action) => {
    return {
      ...state,
      isLoadingRecording: false,
    };
  }),
  on(EditMusicPieceActions.createdRecording, (state, action) => {
    return {
      ...state,
      recording: action.recording,
      isLoadingRecording: false,
      isUploadingRecording: true,
    };
  }),
  on(EditMusicPieceActions.recordingUploadFinished, (state, _) => {
    let updatedRecording: Recording | undefined;
    if (state.recording) {
      updatedRecording = { ...state.recording, uploadFinished: true };
    }
    return {
      ...state,
      recording: updatedRecording,
      isUploadingRecording: false,
    };
  }),
  on(EditMusicPieceActions.recordingRenderingFinished, (state, action) => {
    return {
      ...state,
      recording: action.recording,
    };
  }),
  on(EditMusicPieceActions.createdMusicScore, (state, action) => {
    return {
      ...state,
      musicScore: action.musicScore,
      isLoadingMusicScore: false,
      isUploadingMusicScore: true,
    };
  }),
  on(EditMusicPieceActions.musicScoreUploadFinished, (state, _) => {
    return {
      ...state,
      isUploadingMusicScore: false,
    };
  }),
  on(EditMusicPieceActions.sharedMusicPiece, (state, action) => {
    return {
      ...state,
      sharedMusicPieceReceivers: [
        ...state.sharedMusicPieceReceivers,
        {
          email: action.receiver,
          name: action.receiver,
          creation: new Date(),
          isPartOfLinkedCollection: false,
          linkedMusicPiece: "",
        },
      ],
    };
  }),
  on(EditMusicPieceActions.loadedSharedMusicPieceReceivers, (state, action) => {
    return {
      ...state,
      sharedMusicPieceReceivers: action.receivers,
    };
  }),
  on(EditMusicPieceActions.unshareMusicPiece, (state, action) => {
    // we are optimistic
    const receiversWithoutUnshared = state.sharedMusicPieceReceivers.filter(
      (mp) => mp.linkedMusicPiece != action.linkedMusicPiece,
    );
    return {
      ...state,
      sharedMusicPieceReceivers: receiversWithoutUnshared,
    };
  }),
  on(EditMusicPieceActions.musicPieceListingLoaded, (state, action) => {
    return {
      ...state,
      listing: action.listing,
    };
  }),
  on(EditMusicPieceActions.createdMusicPieceListing, (state, action) => {
    return {
      ...state,
      listing: action.listing,
    };
  }),
  on(EditMusicPieceActions.updatedMusicPieceListing, (state, action) => {
    return {
      ...state,
      listing: action.listing,
    };
  }),
  on(EditMusicPieceActions.deletedMusicPieceListing, (state, action) => {
    return {
      ...state,
      listing: undefined,
    };
  }),
  on(EditMusicPieceActions.updateRecordingUsesMultipleTracks, (state, _) => ({
    ...state,
    isUpdatingRecording: true,
  })),
);

const selectEditMusicPieceFeature = (state: AppState) => state.editMusicPiece;

export const selectInitialEditMusicPieceData = createSelector(
  selectEditMusicPieceFeature,
  (emp) => {
    return {
      isNew: emp.isNew,
      isLinkedMusicPiece: emp.isLinkedMusicPiece,
      name: emp.name,
    };
  },
);

export const selectEditMusicPieceData = createSelector(
  selectEditMusicPieceFeature,
  (emp) => {
    return {
      isLoading: emp.isLoading,
      musicPiece: emp.musicPiece,
      linkedMusicPiece: emp.linkedMusicPiece,
    };
  },
);

export const selectMusicScoreAndRecording = createSelector(
  selectEditMusicPieceFeature,
  (emp) => {
    return {
      musicScore: emp.musicScore,
      recording: emp.recording,
      isLoadingMusicScore: emp.isLoadingMusicScore,
      isLoadingRecording: emp.isLoadingRecording,
      isUploadingRecording: emp.isUploadingRecording,
      isUploadingMusicScore: emp.isUploadingMusicScore,
      isUpdatingRecording: emp.isUpdatingRecording,
    };
  },
);

export const selectSharedMusicPieceReceivers = createSelector(
  selectEditMusicPieceFeature,
  (emp) => emp.sharedMusicPieceReceivers,
);

export const selectListing = createSelector(
  selectEditMusicPieceFeature,
  (emp) => emp.listing,
);
